import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import SelectBox from "devextreme-react/select-box";
import axios from 'axios';
import { pushslots } from "../../services/PushSlots";
import LoaderPortal from "../Loader/Loader";
import { API_URL } from "../../apiCalls";
 
const timeSlotSelection = [
  "11.00 AM",
  "12.00 PM",
  "01.00 PM",
  "02.00 PM",
  "03.00 PM",
  "04.00 PM",
  "05.00 PM",
  "06.00 PM",
];
const now = new Date();
var availableSlots;
 
const TimeSlot = ({ meetingDate, onDateChange, meetDate}) => {
  const displayTime = localStorage.getItem('mytime');
  const selectedMeetingDate = meetingDate.split('-')[2];
  const navigate = useNavigate();
  const [fromDateOne, setFromDateOne] = React.useState("");
  const [timeSlots, setTimeSlots] = React.useState([]);
  const [bookingDate, setBookingDate] = React.useState("");
  const [selectedTimeSlot, setSetSelectedTimeSlot] = React.useState("");
  const [mySelection, setMySelection] = React.useState("");
  const [loader, setLoader] = React.useState(true);
 
  const slots = React.useMemo(() => {
    const now = new Date();
    const currentHour = now.getHours();
    const selectedDate = new Date(meetingDate); // Parse meetingDate into a Date object
    const isToday = selectedDate.toDateString() === now.toDateString();
  
    let startHour, endHour;
  
    if (isToday) {
      // For today, start after the current hour
      startHour = currentHour + (now.getMinutes() > 35 ? 2 : 1); // +2 if minutes > 35, else +1
      startHour = Math.max(startHour, 11); // Ensure start hour is at least 11 AM
    } else {
      // For future dates, start at 11 AM
      startHour = 11;
    }
  
    endHour = 18; // End hour is always 6 PM
  
    // Generate slots
    const slots = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      const meridian = hour >= 12 ? "PM" : "AM";
      const formattedHour = hour % 12 || 12; // Convert 24-hour format to 12-hour
      const slotTime = `${formattedHour.toString().padStart(2, "0")}:00 ${meridian}`;
      slots.push(slotTime);
    }
  
    return slots;
  }, [meetingDate]);
  
  const fetchMeetingSlots = React.useCallback(async () => {
    const response = await axios.post(`${API_URL}api/getSchedule`, {
      meetingDate: meetingDate,
    });
  
    let jsonData = response.data;
    
    if (typeof jsonData === "string") {
      jsonData = JSON.parse(jsonData);
    }
  
    availableSlots = jsonData.value[0].availabilityView.split('');
    console.log("this is my available slot : " ,availableSlots);
    const slotsresponse = await pushslots(slots, availableSlots);
    
    setLoader(false);
    setTimeSlots(slotsresponse);
    setBookingDate(meetingDate);
    localStorage.setItem("flag", "true");
    localStorage.setItem("mytime", slotsresponse[0]);
    setMySelection(localStorage.getItem('mytime'));
  }, [meetingDate]);
  
  
  React.useEffect(() => {
    setLoader(true);
    const flag = localStorage.getItem("flag");
    const selSlot = localStorage.getItem("selectedslot");
    if (flag === "true") {
      setMySelection(localStorage.getItem("mytime"))
      setTimeSlots(slots);
      setLoader(false);
    } else {
      fetchMeetingSlots();
    }
  }, [selectedMeetingDate, meetingDate, slots, fetchMeetingSlots]);
 
  const onTimeSlotChanged = (e) => {
    // console.log(e.value);
 
    let selectedTimeForMeet = parseInt(e.value.split(':')[0]);
    if(selectedTimeForMeet < 10){
      selectedTimeForMeet+=12;
      setSetSelectedTimeSlot(selectedTimeForMeet);
    }
    setSetSelectedTimeSlot(selectedTimeForMeet);
    onDateChange(e.value);
    localStorage.setItem("mytime",e.value);
    setMySelection(e.value);
  };
 
  return(
<>
    {loader && <LoaderPortal />}
      <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
      
      <div className="col-md-7">
        <h4
          style={{
            fontSize: "20px",
            fontWeight: 700,
            marginTop: "25px",
            marginBottom: "22px",
          }}
        >
          Select a Time Slot
        </h4>
        <div style={{ marginBottom: "50px" }}>
          <SelectBox
            dataSource={timeSlots}
            label="Book a Time Slot"
            labelMode="floating"
            defaultValue={localStorage.getItem("mytime")}
            onValueChanged={onTimeSlotChanged}
            
          />
 
          <br />
          <Link
          to="/scheduleTime/phoneAuthentication/EnterDetails"
          state={{meetingDate: meetingDate, selectedTimeSlot: selectedTimeSlot, mySelection: mySelection, displayTime: displayTime}}
          style={{ textDecoration: "none" }}>
            <Button

              variant="contained"
              style={{
                marginTop: "30px",
                width: "160px",
                height: "40px",
                backgroundColor: "#337AB7",
                fontSize: "15px",
                color: "white",
                borderRadius:"4px",
                border:"none"
              }}
            >
              Confirm
            </Button>
          </Link>
        </div>
      </div>
    </div>
    </>
  );
};
export default TimeSlot;