import axios from 'axios'
// export const API_URL = "http://localhost:8000/";
export const API_URL = "https://schedular.swift-send.click/"


export const sendOtpMail = async(payload)=>{
    const responseBody = {
       repsonseData: null ,
       hasError:false ,
       error:null 
    }

  
    try {
        const response = await axios.post(`${API_URL}api/meetings/sendVerificationEmail` ,payload);
        console.log("this is the response of the api call : " , response);
        responseBody.repsonseData = response.data;


        return responseBody;
    } catch (error) {
        responseBody.hasError = true;
        responseBody.error = error
        return responseBody;
    }
}

export const verifyOtp = async(payload)=>{
    const responseBody = {
        repsonseData: null ,
        hasError:false ,
        error:null 
     }

     try {
        const response = await axios.post(`${API_URL}api/meetings/verification` ,payload);
        console.log("this is the response of the api call : " , response);
        responseBody.repsonseData = response.data;


        return responseBody;
    } catch (error) {
        responseBody.hasError = true;
        responseBody.error = error

        return responseBody;
    }
 
}

export const  scheduleMeetingApi = async(payload)=>{
    const responseBody = {
        repsonseData: null ,
        hasError:false ,
        error:null 
     }

     try {
        const response = await axios.post(`${API_URL}api/meetings/booking`, payload);
        responseBody.repsonseData = response.data;
        return responseBody;

     } catch (error) {
        responseBody.hasError=true;
        responseBody.error=error;

        return responseBody;
     }

};
