import React from 'react';
import LoaderImg from './loader.gif';
import  ReactDOM  from 'react-dom';
 
// added
import './Loader.css';
 
const LoaderPortal = () => {
 
  return (
    <div className="loader-container">
      <img src={LoaderImg} alt="Loading..." className="loader-gif" />
    </div>
  );
};
 
export default LoaderPortal;