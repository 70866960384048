import React, { useEffect } from 'react';
import { BrowserRouter,  Route, Routes } from 'react-router-dom';
import './App.css';
import CalendarMain from './components/calendar/CalendarMain';
import TimeSlotMain from './components/Timeslotmain/TimeSlotMain';
import CalendarRender from './components/Image/Image';
import Form from './components/Form/Form';
import ValidEmail from './components/ValidEmail/ValidEmail';
import Bookmeeting from './components/BookMeeting/BookMeeting';
import IsEmailVerified from './components/IsEmailVerified/IsEmailVerified';

function App() {

  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CalendarRender />} />

        <Route path="/scheduleTime" element={<TimeSlotMain />} />
        <Route
          path="/scheduleTime/phoneAuthentication/EnterDetails"
          element={<Form />}
        />
        <Route
          path="/scheduleTime/phoneAuthentication/EnterDetails/EmailVerification"
          element={<ValidEmail />}
        />
        <Route
          path="/scheduleTime/phoneAuthentication/EnterDetails/EmailVerification/Status"
          element={<IsEmailVerified />}
        />

        {/* After click on the email link: redirection here */}
        <Route
          path="/bookmeeting"
          element={<Bookmeeting />}
        />
      </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
