/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import "./OtpPopup.css";
import { Button } from "devextreme-react";

const OtpInput = ({ length = 6, onOtpSubmit, setOtpPopupVisible, setOtp, otp, resendOtp ,register }) => {
  const inputRefs = useRef([]);
  const [timer, setTimer] = useState(60); // Countdown timer (1 minute)
  const [canResend, setCanResend] = useState(false);

  // Focus on the first input field on mount
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  // Start the countdown timer
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCanResend(true); // Enable the "Resend OTP" link
    }
  }, [timer]);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // Allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // Move to the next input if the current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmitOtp = () => {
    onOtpSubmit();
  };

  const handleResendOtp = () => {
    setTimer(60); // Reset the timer
    setCanResend(false); // Disable "Resend OTP" link
    register(); // Call the resend OTP function
  };

  return (
    <>
      <div className="header-container">
        <div className="left">
          <span className="header-title">Otp Verification</span>
        </div>
        <div className="right">
          <Button
            icon="close"
            onClick={() => {
              setOtpPopupVisible(false);
            }}
          />
        </div>
      </div>

      <hr />
      <div className="otp-main">
        {otp.map((value, index) => {
          return (
            <input
              key={index}
              type="text"
              ref={(input) => (inputRefs.current[index] = input)}
              value={value}
              onChange={(e) => handleChange(index, e)}
              onClick={() => handleClick(index)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="otpInput"
            />
          );
        })}
      </div>

      <Button
        className="otpContinuebtn"
        text="Continue"
        width={"100%"}
        onClick={handleSubmitOtp}
      />

      {/* Resend OTP Timer */}
      <div className="resend-otp">
        {canResend ? (
          <button className="resend-link" onClick={handleResendOtp}>
            Resend OTP
          </button>
        ) : (
          <span className="resend-text">Resend OTP in {timer}s</span>
        )}
      </div>
    </>
  );
};

export default OtpInput;
