import React, { useEffect, useRef, useState } from "react";
import { Form, GroupItem } from "devextreme-react/form";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import "devextreme/dist/css/dx.light.css";
import LoadPanel from "devextreme/ui/load_panel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Popup, TextArea, TextBox } from "devextreme-react";
import OtpPopup from "../otpPopup/OtpPopup";
import { scheduleMeetingApi, sendOtpMail, verifyOtp } from "../../apiCalls";

const FormMain = (props) => {
  const propData = { props };
  const meetData = propData.props.meetData;
  const navigate = useNavigate();

  // States
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [subject, setSubject] = useState("");
  const [flag, setFlag] = useState(false);
  const [docId, setDocId] = useState("");
  const [loader, setLoader] = useState(false);
  const [otpPopupVisible, setOtpPopupVisible] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const length = 6;
  const [otp, setOtp] = useState(new Array(length).fill(""));

  const loadPanelRef = useRef(null);

  const onOtpSubmit = async () => {
    const otpString = otp.join("");
    console.log("otp : ", otpString);
    const payload = {
      otp: otpString,
      email: email,
    };
    const response = await verifyOtp(payload);

    if (response.hasError === false) {
      setOtpPopupVisible(false);
      setFlag(true);
      return notify(
        { message: "Email verified successfully...", width: 500 },
        "success",
        1000,
      );
    }

    if (response.hasError === true) {
      notify({ message: `${response.error}`, width: 500 }, "error", 1000);
    }
  };

  function validateEmail(email) {
    const emailRegex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    const invalidDomains = /\.([a-zA-Z]{2,})\.\1$/;

    if (emailRegex.test(email) && !invalidDomains.test(email)) {
      return true; // Valid email
    }
    return false; // Invalid email
  }

  function validateWebsiteURL(url) {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,2}(\/[a-zA-Z0-9#?=&._%-]*)?$/;
    const invalidDomains = /\.([a-zA-Z]{2,})\.\1$/; // Blocks repetitive domains like .com.com

    if (urlRegex.test(url) && !invalidDomains.test(url)) {
      return true; // Valid URL
    }
    return false; // Invalid URL
  }

  const register = async () => {
    // Check if all fields are filled
    if (!email || !name || !website || !companyName || !subject) {
      return notify(
        {
          message: "Please add all the fields to schedule the meet...",
          width: 500,
        },
        "error",
        1000,
      );
    }

    // Validate email format
    if (!validateEmail(email)) {
      return notify(
        { message: "Please enter a correct mail ...", width: 500 },
        "error",
        1000,
      );
    }

    if (!validateWebsiteURL(website)) {
      return notify(
        {
          message: "Please enter a valid website URL ...",
          width: 500,
        },
        "error",
        1000,
      );
    }

    if (companyName.length < 5) {
      return notify(
        {
          message: "Please enter more then 5 character in company name ...",
          width: 500,
        },
        "error",
        1000,
      );
    }

    if (subject.length < 10) {
      return notify(
        {
          message: "Please enter more then 10 character in the Subject ...",
          width: 500,
        },
        "error",
        1000,
      );
    }

    try {
      setLoader(true);
      const payload = {
        email,
        name,
        subject,
        meetDate: meetData.meetingDate,
        meetTime: meetData.selectedTimeSlot,
      };

      const response = await sendOtpMail(payload);

      if (response.hasError === false) {
        setDocId(Date.now().toString()); // Mock document ID
        setOtpPopupVisible(true);

        notify(
          {
            message: "Verification email sent. Please check your inbox.",
            width: 500,
          },
          "info",
          2000,
        );
        setLoader(false);
      }
    } catch (error) {
      console.error("Error sending verification email:", error);
      setLoader(false);
    }
  };

  // Function to schedule the meeting after email verification
  const scheduleEvent = async () => {
    try {
      setLoader(true);

      // Ensure selectedTimeSlot is valid
      const selectedTimeSlot = parseInt(meetData.selectedTimeSlot);
      if (isNaN(selectedTimeSlot)) {
        throw new Error("Invalid selected time slot");
      }

      const startTime = String(selectedTimeSlot).padStart(2, "0"); // Ensure HH format
      const endTime = String(selectedTimeSlot + 1).padStart(2, "0"); // Add 1 hour for end time

      const meetingDetails ={
        name: name,
        email: email,
        subject: subject, 
      }
      const meetConfig = {
        meetingDetails:meetingDetails,
        subject,
        body: {
          contentType: "HTML",
          content: "Please confirm your meeting status.",
        },
        start: {
          dateTime: `${meetData.meetingDate}T${startTime}:00:00`,
          timeZone: "India Standard Time",
        },
        end: {
          dateTime: `${meetData.meetingDate}T${endTime}:00:00`,
          timeZone: "India Standard Time",
        },
        location: { displayName: "Online Teams Meeting" },
        attendees: [
          { emailAddress: { address: email, name: name }, type: "required" },
        ],
        isOnlineMeeting: true,
        onlineMeetingProvider: "teamsForBusiness",
      };

      console.log("This is the payload:", meetConfig);

      // API request to schedule meeting

      const response = await scheduleMeetingApi(meetConfig ,meetingDetails);

      if (response.hasError === false) {
        notify(
          {
            message:
              "Meeting has been scheduled. Check your email for details.",
            type: "success",
            width: 500,
          },
          "success",
          2000,
        );
        navigate("/"); // Navigate to another page after scheduling
      }
    } catch (error) {
      setLoader(false);
      console.error("Error scheduling meeting:", error);
    }
  };

  return (
    <React.Fragment>
      <Popup visible={otpPopupVisible} width={"auto"} height={"auto"}>
        <OtpPopup
          setOtpPopupVisible={setOtpPopupVisible}
          setOtp={setOtp}
          otp={otp}
          onOtpSubmit={onOtpSubmit}
          register={register}
        />
      </Popup>

      <div style={{ margin: "25px", marginTop: "20px", marginBottom: "40px" }}>
        <h3 style={{ fontSize: "18px", fontWeight: 700, marginBottom: "22px" }}>
          Enter your Details
        </h3>

        <Form colCount={2} labelMode='floating' labelLocation='left'>
          <GroupItem>
            <TextBox
              label='Full Name'
              labelMode='floating'
              defaultValue={name}
              onValueChanged={(e) => setName(e.value)}
            />
            <TextBox
              label='Email Address'
              labelMode='floating'
              mode='email'
              defaultValue={email}
              onValueChanged={(e) => setEmail(e.value)}
            />
            <TextBox
              label='Website'
              labelMode='floating'
              defaultValue={website}
              onValueChanged={(e) => setWebsite(e.value)}
            />
          </GroupItem>

          <GroupItem>
            <TextBox
              label='Company Name'
              labelMode='floating'
              defaultValue={companyName}
              onValueChanged={(e) => setCompanyName(e.value)}
            />
            <TextArea
              label='Subject'
              labelMode='floating'
              defaultValue={subject}
              onValueChanged={(e) => setSubject(e.value)}
            />
          </GroupItem>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          
          {flag === false && (
            <Button
              width={250}
              height={40}
              text='Verify your Email'
              onClick={register}
            />
          )}
          {flag === true && (
            <Button
              width={250}
              height={40}
              text='Schedule the Meeting'
              onClick={scheduleEvent}
            />
          )}
        </div>
        <div ref={loadPanelRef}></div>
      </div>
    </React.Fragment>
  );
};

export default FormMain;
